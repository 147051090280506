/* == Because jquery is loaded by default, required by Woocommerce / whosale ... == */

(function ($) {

  // ---------- APP START
  var App = {

    config: {
      scrollOffset: 100
    },

    init: function () {
      // Remove focus state when clicking on a button with a mouse
      $(':not(form) button, a').on('click', function (e) {
        if (e.clientX && e.clientY)
          $(this).blur();
      });

      $(window).scroll(function () {
        if ($(window).scrollTop() > 76) {
          $('body').addClass('is-shrink');
        } else {
          $('body').removeClass('is-shrink');
        }
      });

      App.initMenu();
      App.initSlider();
      App.initCounterKeys();
      App.initAccordion();
      App.initFormation();
      App.WC.init();
    },

    /*load: function(){
    },*/

    scroll: function (element, offset) {
      offset = offset === undefined ? App.config.scrollOffset : offset;
      $('html, body').clearQueue().animate({ scrollTop: $(element).offset().top - offset }, 'slow');
    },

    initMenu: function () {
      var $current = '';
      var $action = '';
      $('.js-menu').on('click touch', function (e) {
        e.preventDefault();
        $action = $(this).data('id') == 'menu-menu-main-2' ? 'nav2-opened' : 'nav-opened';
        var $navtarget = $('#' + $(this).data('id'));
        var $navparent = $navtarget.parent();
        $('.navigation-menu').removeClass('active');
        if (this == $current && $navparent.attr('aria-hidden') === 'false') {
          $navparent.attr('aria-hidden', 'true');
          $($navparent).one('animationend', function () {
            $('body').removeClass($action);
          });
        } else {
          $navparent.attr('aria-hidden', 'false');
          $navtarget.addClass('active');
          $('body').addClass($action);
        }
        $current = this;
      });

      $('.js-menu-close').on('click touch', function () {
        $('.navigation-menu').removeClass('active');
        $(this).parent().attr('aria-hidden', 'true');
        $(this).parent().one('animationend', function () {
          $('body').removeClass($action);
        });
      });
    },

    /* Bloc Accordion
    ----------------------------------*/
    initAccordion: function () {

      $('.tab-content').each(function () {
        $(this).css("--height", parseInt(this.scrollHeight) + "px");
      });

      $('.tab-label').on('click touch', function (e) {
        e.preventDefault();
        var $parent = $(this).parent();
        $('.tab').not($parent).removeClass('active');
        $parent.toggleClass('active');
      });
      
      $('.js-product-read-more').on('click touch', function (e) {
        var $desc =  $('.block-accordion').find('#description');
        $('.tab').removeClass('active');
        $($desc).addClass('active');
        App.scroll($desc);
      });
    },

    /* Sliders
    ----------------------------------*/
    initSlider: function () {
      $('.swiper').each(function (index, element) {
        var $offset = element.dataset.offset ? 0.2 : 0;
        var $items = parseFloat(element.dataset.items) + $offset;
        var $space = $items >= 6 ? 6 : 20;
        var $nav = element.getElementsByClassName('swiper-pagination');
        new Swiper(element, {
          slidesPerView: $items,
          speed: 400,
          spaceBetween: $space,
          autoHeight: true,
          pagination: {
            el: $nav[0],
            clickable: true
          },
          navigation: {
            nextEl: element.querySelector('.swiper-button-next'),
            prevEl: element.querySelector('.swiper-button-prev')
          },
          breakpoints: {
            320: {
              slidesPerView: 1.2
            },
            768: {
              slidesPerView: 2.4
            },
            1024: {
              slidesPerView: $items
            }
          }
        });
      });
    },

    /* Counters keys
    ----------------------------------*/
    initCounterKeys: function () {

      $('.card-key-unit').each(function () {
        var count = $(this),
          decimal = (count.text().indexOf('.') !== -1) ? true : false,
          unit = (count.text().indexOf('%') !== -1) ? count.text().substring(count.text().indexOf('%'), count.text().length) : false,
          to = (unit !== false) ? count.text().substring(0, count.text().indexOf('%')) : count.text(),
          performed = false,
          viewport = {},
          bounds = {};

        bounds.top = count.offset().top;
        bounds.bottom = bounds.top + count.outerHeight();

        performAnimation();

        $(window).on('scroll resize', performAnimation);

        function performAnimation() {
          viewport.top = $(window).scrollTop();
          viewport.bottom = viewport.top + $(window).height();

          if (performed === false && bounds.top <= viewport.bottom && bounds.bottom >= viewport.top) {
            count.prop('counter', 0).animate({
              counter: to
            }, {
              duration: 1500,
              step: function (now) {
                var value = (decimal !== false) ? now.toString().substring(0, now.toString().indexOf('.') + 2) : Math.ceil(now);
                if (unit !== false) { value += '' + unit; }
                count.text(value);
              }
            });
            performed = true;
          }
        }
      });
    },

    /* Formations - filters
    ----------------------------------*/
    initFormation: function () {

      $('.formation-city-js').on('change', function (e) {
        e.preventDefault();
        $selection = $(this).val();
        $('.card-formation').removeClass('hide');
        if ($selection != '') {
          $('.card-formation').each(function (el, index) {
            if ($(this).data('id') != $selection)
              $(this).addClass('hide');
          });
        }
      });
    },

    /* Popup - Glightbox
    ----------------------------------*/
    initGLB: function () {
      const lightbox = GLightbox();

      lightbox.on('open', (target) => {
       // console.log('lightbox opened');
      });

    },

    /* Woocommerce
    ----------------------------------*/
    WC: {

      init: function () {

        $('.copy-input').on('click touch', function (e) {
          e.preventDefault();
          var copyMess = document.getElementById("input-copied");
          var copyText = document.getElementById("input-copy");
          copyText.select();
          copyText.setSelectionRange(0, 99999); // For mobile
          if(navigator.clipboard) {
            navigator.clipboard.writeText(copyText.value);
            copyMess.innerHTML = 'Url copiée : ' + copyText.value;
            copyMess.classList.add("copied");
          }
          else{
            copyMess.classList.add("copied");
            copyMess.innerHTML = 'Url copiée : ' + copyText.value;
          }
        });

        function initCartUpdater() {

        }
      }
    }
  };

  // ---------- APP END

  // Init app
  $(document).ready(App.init);
  //$(window).on('load', App.load); // jQuery 3
  window.App = App;
  //window.gevt = App.gevt;

})(jQuery);