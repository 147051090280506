(function ($) {

  var Locator = {

    initMap: function () {
      if ($('#store-locator')) {
        var $locator = $('#store-locator');
        Locator.datagps = $locator.data('gps') || null;
        // Init map
        Locator.map = new google.maps.Map($locator.get(0), {
          center: new google.maps.LatLng(47.5, 0),
          zoom: 7,
          minZoom: 2,
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER
          },
          styles: Locator.config.mapStyles
        });

        $.getJSON("/wordpress/wp-admin/admin-ajax.php?action=load_locations", function (data) {
          Locator.initStoreLocator(data);
        });
      }
    },

    initStoreLocator: function (data) {
      // Init markers
      var map = Locator.map;
      //var list 			= $('#map-list');
      //var listcount	= $('#map-list-result');
      var cards = null;
      var markers = [];
      var newmarkers = [];
      var markerCluster = null;
      var locations = data.locations;
      var resultmin = 5; // Min results to free max zoom
      var lowresultzoom = 16; // In case of low search results
      var radius = 5000; // Search around 5 kms

      if(null !== Locator.datagps) {
        var $datagps = Locator.datagps.split(',');
        var $latlng = new google.maps.LatLng($datagps[0], $datagps[1]);
        var $marker = new google.maps.Marker({
          map: map,
          position: $latlng,
          icon: setIcon(0)
        });
        map.setZoom(17);
        map.panTo($marker.position);
      }

      else {

        var defaultBounds = new google.maps.LatLngBounds(
          new google.maps.LatLng(40, -5),
          new google.maps.LatLng(50, 10)
        );
    
        var input = document.getElementById('map-search-input');
        var select_country = document.getElementById('map-search-countries');
        
        var countries = ['fr', 'ch', 'be','lu'];
        
        var options = {
            types: ['(cities)']
          };
        
        var searchBox = new google.maps.places.Autocomplete(input);
            searchBox.setComponentRestrictions({'country': countries}); // up to five countries
            searchBox.setFields(['geometry','name']);
            
        $(select_country).on('change', function() {
          if(this.value == 2)
            countries = ['de'];
          else if(this.value == 3)
            countries = ['ca','pm'];
          else if(this.value == 4)
            countries = ['es'];
          else if(this.value == 5)
            countries = ['gp', 'gf', 'mq'];
          else if(this.value == 6)
            countries = ['re'];
          else if(this.value == 7)
            countries = ['nc'];
          else if(this.value == 8)
            countries = ['tw'];
          else 
            countries = ['fr', 'ch', 'be','lu'];
            
          searchBox.setComponentRestrictions({'country': countries});
        });
        
        map.addListener('bounds_changed', function() {
          searchBox.setBounds(map.getBounds());
          //updateList();
        });

        // Set All markers
        if (locations.length) {
          $.each(locations, function (key, val) {
            createMarker(val);
          });
        }

        var message = $('#map-message');
				message.on( "click", function() {
					$(this).css('display','none');
				});

        var placelatlng;
        searchBox.addListener('place_changed', function() {
          
          message.css('display','none');
          
          var places = searchBox.getPlace();
          
          if (places.length == 0) {
            return;
          }
          
          var results = 0;
          map.setOptions({maxZoom:21});
          
          var bounds = new google.maps.LatLngBounds();
          
          placelatlng = new google.maps.LatLng(places.geometry.location.lat(), places.geometry.location.lng());
          
          cards = [];
          markers.forEach(function(marker) {
            var markerlatlng = new google.maps.LatLng(marker.getPosition().lat(), marker.getPosition().lng());
            var distance_from_location = google.maps.geometry.spherical.computeDistanceBetween(placelatlng, markerlatlng);
            if (distance_from_location <= radius) {
              bounds.extend(marker.getPosition());
              cards.push(marker.itemdata);
              results++;
            }
          });
          
          // If no results, relaunch with greater radius (30 Kms)
          if(results < 1) {
            cards = [];
            markers.forEach(function(marker) {
              var markerlatlng = new google.maps.LatLng(marker.getPosition().lat(), marker.getPosition().lng());
              var distance_from_location = google.maps.geometry.spherical.computeDistanceBetween(placelatlng, markerlatlng);
              if (distance_from_location <= (radius*6)) {
                bounds.extend(marker.getPosition());
                cards.push(marker.itemdata);
                results++;
              }
            });
          }
          
          if(results > 0 ) {
            map.fitBounds(bounds);
            if(results < 4) {
              map.setZoom(13);
            }
          }
          else {
            map.panTo(placelatlng);
            map.setZoom(11);
            message.css('display','block');
          }
                  
          /*google.maps.event.addListenerOnce(map, 'bounds_changed', function(event) {
            if (this.getZoom()){
              map.setOptions({zoom:18});
            }
          });	*/
          
        });
      }


      

      // ========== Markers / Cluster ==========
      function createMarker(item) {
        var lat = parseFloat(item.lat);
        var lng = parseFloat(item.lng);
        var latlng = new google.maps.LatLng(lat, lng);

        var marker = new google.maps.Marker({
          map: map,
          position: latlng,
          icon: setIcon(item.cat),
          itemdata: item
        });

        google.maps.event.addListener(marker, 'click', function () {
          popMarker(marker);
        });

        markers.push(marker);
      }

      var clusterStyles = [
        { textColor: 'black', url: '/themes/gaia/assets/icons/cluster-m1.png', height: 32, width: 32 },
        { textColor: 'black', url: '/themes/gaia/assets/icons/cluster-m2.png', height: 32, width: 32 },
        { textColor: 'black', url: '/themes/gaia/assets/icons/cluster-m3.png', height: 48, width: 48 },
        { textColor: 'black', url: '/themes/gaia/assets/icons/cluster-m4.png', height: 48, width: 48 },
        { textColor: 'black', url: '/themes/gaia/assets/icons/cluster-m5.png', height: 48, width: 48 }
      ];

      markerCluster = new MarkerClusterer(map, markers, {
        styles: clusterStyles,
        maxZoom: 11
      }
      );

      function popMarker(marker) {
        var $popin = $('.map-popin');
        var $page_url = $($popin).data('url');
        $('.js-popin-close').on('click touch', function (e) {
          e.preventDefault();
          $popin.removeClass('active');
        });

        $popin.addClass('active');

        setContentPopin(marker.itemdata, $page_url);

        /*overlay.update(marker.getPosition(), setContent(marker.itemdata));
        overlay.show();*/
        /*var action = 'clic-map', 
            cat = 'clic',
            label = marker.itemdata.nom;
        App.tagManager.sendEvent(action,cat,label,'');*/
      }

      function setIcon(cat) {
        return {
          path: Locator.config.mapIcon,
          fillColor: "#CD9A67",
          fillOpacity: 1,
          scale: 1,
          strokeColor: "#cccccc",
          strokeWeight: 0,
          anchor: ({ x: 11, y: 32 })
        };
      }

      function setContentPopin(item, $page_url) {
        var $this = this;
        $('.map-popin__name').html(item.nom);
        $('.map-popin__partner span').html(item.date_colorist);
        $('.map-popin__adress address').html(item.address);
        $('.map-popin__adress span').html(item.zip + ' ' + item.ville);
        var $tags = '';
        if (item.colorist == 1) $tags += '<span>Coloration végétale</span>';
        if (item.vente_soins == 1) $tags += '<span>Produit de soin</span>';
        if (item.vrac_soin == 1 || item.vrac_couleur == 1) $tags += '<span>Vente en vrac</span>';
        $('.map-popin__tags').html($tags);

        // From map view page salon
        if($('.js-locator-page-card').length) {
          var $btn_card = $('.js-locator-page-card');
          $btn_card.removeAttr('onclick');
          $btn_card.one('click touch', function (e) {
            $.ajax({
              dataType: "json",
              type: "GET",
              cache: false,
              url: "/wordpress/wp-admin/admin-ajax.php?action=get_salon&salonkey=" + item.key,
              success: function(data) {
                setContentCard(data);
              }
            });
          });
        }

        // From map select salon
        if($('.js-selected-salon').length) {
          var $btn_salon = $('.js-selected-salon');
          $btn_salon.removeAttr('onclick');
          $btn_salon.one('click touch', function (e) {
            $.ajax({
              dataType: "json",
              type: "GET",
              cache: false,
              url: "/wordpress/wp-admin/admin-ajax.php?action=get_salon_selected&salonkey=" + item.key,
              success: function(data) {
                setChoiceSalon(data);
                $('#pum-2607').trigger('pumSetCookie');
                setTimeout(function () {
                    PUM.close(2607);
                }, 200);
              }
            });
          });
        }
      }

      function setContentCard(data) {
        var $container = $('.card-salon-wrapper');
        var $backbtn = $container.find('.js-back-to-map');
        var $cardsalon = $container.find('.card-salon');
        $backbtn.removeAttr('onclick');
        $cardsalon.empty();
        $cardsalon.html(data);
        $container.addClass('active');
        $($backbtn).one('click touch', function (e) {
          $container.removeClass('active');
        });
        $('.map-popin').removeClass('active');
      }

      function setChoiceSalon(data) {
        var $target_data = $('#usr_referral_salon_data') || null;

       if(undefined !== $target_data) {
          $target_data.empty();
          $target_data.html(data.datasalon);
          $target_data.parent().find('.btn-salon-select').addClass('selected');
        }
      }

      function updateMap(data) {

      }
    },

    config: {
      mapStyles: [{ "elementType": "geometry", "stylers": [{ "color": "#f5f5f5" }] }, { "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "elementType": "labels.text.fill", "stylers": [{ "color": "#616161" }] }, { "elementType": "labels.text.stroke", "stylers": [{ "color": "#f5f5f5" }] }, { "featureType": "administrative.land_parcel", "elementType": "labels.text.fill", "stylers": [{ "color": "#bdbdbd" }] }, { "featureType": "poi", "elementType": "geometry", "stylers": [{ "color": "#eeeeee" }] }, { "featureType": "poi", "elementType": "labels.text.fill", "stylers": [{ "color": "#757575" }] }, { "featureType": "poi.park", "elementType": "geometry", "stylers": [{ "color": "#e5e5e5" }] }, { "featureType": "poi.park", "elementType": "labels.text.fill", "stylers": [{ "color": "#9e9e9e" }] }, { "featureType": "road", "elementType": "geometry", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "road.arterial", "elementType": "labels.text.fill", "stylers": [{ "color": "#757575" }] }, { "featureType": "road.highway", "elementType": "geometry", "stylers": [{ "color": "#dadada" }] }, { "featureType": "road.highway", "elementType": "labels.text.fill", "stylers": [{ "color": "#616161" }] }, { "featureType": "road.local", "elementType": "labels.text.fill", "stylers": [{ "color": "#9e9e9e" }] }, { "featureType": "transit.line", "elementType": "geometry", "stylers": [{ "color": "#e5e5e5" }] }, { "featureType": "transit.station", "elementType": "geometry", "stylers": [{ "color": "#eeeeee" }] }, { "featureType": "water", "elementType": "geometry", "stylers": [{ "color": "#c9c9c9" }] }, { "featureType": "water", "elementType": "labels.text.fill", "stylers": [{ "color": "#9e9e9e" }] }],
      mapIcon: "M10.932 1.276c-2.869 0-5.62 1.14-7.648 3.168s-3.168 4.78-3.168 7.648c0 9.977 9.472 18.251 9.937 18.603 0.245 0.211 0.558 0.326 0.881 0.326s0.636-0.116 0.881-0.326c0.474-0.352 9.937-8.627 9.937-18.603 0-1.421-0.28-2.828-0.824-4.14s-1.341-2.505-2.346-3.51c-1.005-1.004-2.198-1.801-3.511-2.344s-2.72-0.823-4.141-0.822zM10.932 16.148c-0.802 0-1.586-0.238-2.253-0.684s-1.187-1.079-1.494-1.82c-0.307-0.741-0.387-1.556-0.231-2.343s0.543-1.509 1.11-2.076c0.567-0.567 1.29-0.953 2.076-1.11s1.602-0.076 2.343 0.231c0.741 0.307 1.374 0.827 1.82 1.494s0.684 1.451 0.684 2.253c0 1.076-0.427 2.107-1.188 2.868s-1.792 1.188-2.868 1.188z"
    }
  };

  window.Locator = Locator;
})(jQuery);