import "@styles/index.scss";
import GLightbox from 'glightbox';

import "glightbox/dist/css/glightbox.css";

window.GLightbox = GLightbox;

document.addEventListener(
  "DOMContentLoaded",
  () => {
    /* Auto Imports */
    const assetContext = require.context(".", true, /\.(asset)\.(.*?)$/);
    assetContext.keys().forEach(assetContext);
    const styleContext = require.context(".", true, /\.(style)\.(.*?)$/);
    styleContext.keys().forEach(styleContext);
    const scriptsContext = require.context('.', true, /\.js$/i);
    scriptsContext.keys().forEach(scriptsContext)
  },
  { once: true },
);